.projects-grid {
    margin: 0 auto;
    margin-top: 50px;
}

.projects-grid .projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    position: relative;
    gap: 15px;
}

.project {
    cursor: default;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.project:hover,
.project:focus {
    outline: 0px;
}

.project-inner {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    background-color: rgb(23, 42, 69);
    padding: 2rem 1.75rem;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.project-folder {
    color: rgb(100, 255, 218);
}

.project-header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.project-header svg {
    width: 40px;
    height: 40px;
}

.project-links {
    margin-right: -10px;
    color: rgb(168, 178, 209);
}

.project-link {
    position: relative;
    top: -10px;
    padding: 10px;
}

.project-link svg {
    width: 20px;
    height: 20px;
}

.project-name {
    font-size: 22px;
    color: rgb(204, 214, 246);
    margin: 0px 0px 10px;
}

.project-description {
    font-size: 17px;
    color: rgb(168, 178, 209);
}

.project-tech {
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
}

.project-tech li {
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 12px;
    color: rgb(136, 146, 176);
    line-height: 1.75;
    margin-right: 15px;
}

.projects-grid.loading {
    height: 800px;
    margin: unset;
    margin-top: 50px;
}

.projects-grid .stripe {
    height: 30%;
}

@media (max-width: 62.5em) {
    .projects-grid .projects-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
