footer.foot {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: auto;
    min-height: 70px;
    padding: 15px;
}

footer svg {
    display: inline-block;
    height: 15px;
    width: auto;
    fill: currentcolor;
}

.github-stars-forks svg {
    margin-right: 5px;
}

footer div.github-stars-forks > span {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 7px;
}

footer.foot {
    color: rgb(168, 178, 209);
    padding: 10px;
}

footer a,
footer a:hover,
footer a:focus {
    text-decoration: none;
}

.foot .metadata {
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 12px;
    line-height: 1;
}

.foot .social {
    color: rgb(168, 178, 209);
    width: 100%;
    max-width: 270px;
    display: none;
    margin: 0px auto 10px;
}

.foot .social .social-list {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.foot .social-link {
    padding: 10px;
}

.foot .social-link svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 48em) {
    .foot .social {
        display: block;
    }
}
