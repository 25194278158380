.email-sidebar {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 40px;
    z-index: 10;
    color: rgb(168, 178, 209);
}

.email-sidebar .email-item {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

.email-sidebar .email-item::after {
    content: '';
    display: block;
    width: 1px;
    height: 90px;
    background-color: rgb(168, 178, 209);
    margin: 0px auto;
}

.email-sidebar .email-item a {
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 12px;
    letter-spacing: 0.1em;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    margin: 20px auto;
    padding: 10px;
}

.email-sidebar .email-item a:hover,
.email-sidebar .email-item a:focus {
    text-decoration: none;
    transform: translateY(-3px);
}

@media (max-width: 62.5em) {
    .email-sidebar {
        right: 25px;
    }
}

@media (max-width: 48em) {
    .email-sidebar {
        display: none;
    }
}
