.social-sidebar {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: 40px;
    right: auto;
    z-index: 10;
    color: rgb(168, 178, 209);
}

.social-sidebar .social-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.social-sidebar .social-list::after {
    content: '';
    display: block;
    width: 1px;
    height: 90px;
    background-color: rgb(168, 178, 209);
    margin: 0px auto;
}

.social-sidebar .social-item {
    padding: 10px;
}

.social-sidebar svg {
    display: inline-block;
    height: 15px;
    width: auto;
    fill: currentcolor;
}

.social-sidebar .social-item svg {
    width: 18px;
    height: 18px;
}

.social-sidebar .social-item:hover,
.social-sidebar .social-item:focus {
    transform: translateY(-3px);
}

.social-sidebar .social-item::after {
    content: '';
    display: block;
    width: 1px;
    height: 1px;
}

@media (max-width: 62.5em) {
    .social-sidebar {
        right: 25px;
    }
}

@media (max-width: 48em) {
    .social-sidebar {
        display: none;
    }
}
