/* Globals */

#root {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.layout.blur {
    overflow: hidden;
}

.main {
    width: 100%;
    max-width: 1600px;
    min-height: 100vh;
    counter-reset: section 0;
    padding: 200px 150px;
    margin: 0px auto;
}

.main.fill-height {
    padding-top: 0px;
    padding-bottom: 0px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

a.underline {
    text-decoration: underline;
}

.page {
    min-height: 100%;
    min-height: 100vh;
}

.paragraph {
    margin-top: 0;
    margin-bottom: 1rem;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color: rgb(100, 255, 218);
    background-color: transparent;
    line-height: 1;
    cursor: pointer;
    margin-left: 10px;
    font-size: 13px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(100, 255, 218);
    border-image: initial;
    border-radius: 3px;
    padding: 0.75rem 1rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: rgba(100, 255, 218, 0.07);
}

.count {
    color: rgb(100, 255, 218);
}

.main .btn-primary {
    color: rgb(100, 255, 218);
    background-color: transparent;
    font-size: 14px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    line-height: 1;
    cursor: pointer;
    margin-top: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(100, 255, 218);
    border-image: initial;
    border-radius: 3px;
    padding: 1.25rem 1.75rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

main .btn-primary:hover,
main .btn-primary:focus,
main .btn-primary:active {
    color: rgb(100, 255, 218);
    background-color: rgba(100, 255, 218, 0.07);
    font-size: 14px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    line-height: 1;
    cursor: pointer;
    margin-top: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(100, 255, 218);
    border-image: initial;
    border-radius: 3px;
    padding: 1.25rem 1.75rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.github-stars-forks {
    margin-top: 10px;
}

.heading {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    font-size: 32px;
    margin: 10px 0px 40px;
}

.heading::before {
    counter-increment: section 1;
    content: '0' counter(section) '.';
    margin-right: 10px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 20px;
    position: relative;
    bottom: 0px;
}

.heading::after {
    content: '';
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(48, 60, 85);
    position: relative;
    top: -5px;
    margin-left: 20px;
}

.hidden {
    display: inline;
    visibility: hidden;
}

.spinner-border {
    color: rgb(100, 255, 218);
}

.loading {
    width: 100%;
    display: flex;
    height: 800px;
    height: 100vh;
    padding: 1%;
}

.loading-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
}

.stripe {
    height: 5%;
    background-color: #babbbc;
}

.article .stripe {
    height: 10%;
}

.small-stripe {
    width: 40%;
}

.medium-stripe {
    width: 70%;
}

.long-stripe {
    width: 100%;
}

.loading .img,
.loading .stripe {
    animation: hintloading 2s ease-in-out 0s infinite reverse;
    -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@-webkit-keyframes hintloading {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@media (max-width: 62.5em) {
    .heading::after {
        width: 200px;
    }

    .main {
        padding: 0px 100px;
    }

    .main {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .main.fill-height {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

@media (max-width: 48em) {
    .main {
        padding: 0px 50px;
    }

    .main {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .main.fill-height {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .heading {
        font-size: 20px;
    }

    .heading::before {
        font-size: 16px;
    }
}

@media (max-width: 30em) {
    .main {
        padding-top: 125px;
        padding-bottom: 125px;
    }

    .main.fill-height {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
