.projects {
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px auto;
    padding: 100px 0px;
}

.projects svg {
    width: 100%;
    height: 100%;
    fill: currentcolor;
    vertical-align: middle;
}

.projects a {
    display: inline-block;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

a.archive-link::after {
    content: '';
    display: block;
    width: 0px;
    height: 1px;
    position: relative;
    bottom: 0.37em;
    background-color: rgb(100, 255, 218);
    opacity: 0.5;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

a.archive-link::after {
    bottom: 0.1em;
}

a.archive-link:hover::after,
a.archive-link:focus::after,
a.archive-link:active::after {
    width: 100%;
}

.projects a:hover,
.projects a:focus {
    color: rgb(100, 255, 218);
}

a.archive-link {
    display: inline-block;
    text-decoration-skip-ink: auto;
    position: relative;
    cursor: pointer;
    color: rgb(100, 255, 218);
    text-align: center;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    margin: 0px auto;
    margin-top: 50px;
}

a.archive-link:hover,
a.archive-link:focus,
a.archive-link:active {
    color: rgb(100, 255, 218);
    outline: 0px;
}

.projects h4 {
    text-align: center;
}

@media (max-width: 48em) {
    .projects {
        padding: 100px 0px;
    }
}
