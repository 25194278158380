.intro {
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
}

.overline {
    color: rgb(100, 255, 218);
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-weight: normal;
    margin: 0px 0px 20px 3px;
}

.title {
    font-size: 80px;
    line-height: 1.1;
    margin: 0px;
}

.subtitle {
    font-size: 50px;
    line-height: 1.1;
    color: rgb(136, 146, 176);
}

.intro div {
    width: 100%;
}

.description {
    margin-top: 25px;
}

@media (max-width: 62.5em) {
    .intro > h1 {
        font-size: 14px;
    }

    .intro > h2 {
        font-size: 70px;
    }

    .intro > h3 {
        font-size: 40px;
    }
}

@media (max-width: 48em) {
    .intro {
        padding-top: 100px;
    }

    .intro > h2 {
        font-size: 2.5rem;
    }
    .intro > h3 {
        font-size: 1.3em;
    }
}
