::selection {
    background-color: rgb(136, 146, 176);
    color: rgb(204, 214, 246);
}

*,
::before,
::after {
    box-sizing: inherit;
}

:focus {
    outline: none;
}

html {
    box-sizing: border-box;
    width: 100%;
}

body {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: rgb(10, 25, 47);
    color: rgb(136, 146, 176);
    line-height: 1.3;
    font-family: Calibre, 'San Francisco', 'SF Pro Text', -apple-system, system-ui, BlinkMacSystemFont, Roboto,
        'Helvetica Neue', 'Segoe UI', Arial, sans-serif;
    font-size: 20px;
    margin: 0px;
}

svg {
    width: 100%;
    height: 100%;
    fill: currentcolor;
    vertical-align: middle;
}

a {
    display: inline-block;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
a:hover,
a:focus {
    color: #64ffda;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: rgb(204, 214, 246);
    margin: 0px 0px 10px;
    width: 100%;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0px 0px 15px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

code,
kbd,
pre,
samp {
    width: 100%;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
    overflow-x: scroll;
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

button {
    cursor: pointer;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border-radius: 0px;
}

@media (max-width: 30em) {
    body {
        font-size: 18px;
    }
}
