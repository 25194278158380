.about {
    max-width: 1000px;
    position: relative;
    margin: 0px auto;
    padding: 100px 0px;
}

.about-flex-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: flex-start;
}

.about-content {
    width: 60%;
    max-width: 480px;
    /* Don't do side-by-side for now */
    width: initial;
    max-width: initial;
}

.about .skills {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 350px));
    overflow: hidden;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
}

.about .skill {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 13px;
    color: rgb(136, 146, 176);
}

.about .skill::before {
    content: '▹';
    position: absolute;
    left: 0px;
    color: rgb(100, 255, 218);
    font-size: 14px;
    line-height: 12px;
}

@media (max-width: 48em) {
    .about {
        padding: 100px 0px;
    }
    .about-flex-container {
        display: block;
    }
    .about-content {
        width: 100%;
    }
}
