.blog-post a {
    color: rgb(100, 255, 218);
}
.blog-post ol {
    display: block;
    list-style-type: unset;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.blog-post h1,
.blog-post h2,
.blog-post h3,
.blog-post h4,
.blog-post h5,
.blog-post h6 {
    margin: 20px 0px;
}

.blog-post blockquote,
.blog-post p,
.blog-post pre {
    margin: 0 0 2em;
}
