.contact {
    text-align: center;
    max-width: 600px;
    padding: 100px 0px;
    margin: 0px auto 100px;
}

.contact .heading {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    color: rgb(100, 255, 218);
    font-size: 16px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-weight: normal;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 10px 0px 20px;
}

.contact .heading::after {
    content: unset;
    display: unset;
    height: unset;
    width: unset;
    background-color: unset;
    position: unset;
    top: unset;
    margin-left: unset;
}

.contact .title {
    font-size: 60px;
    margin: 0px 0px 20px;
}

.main .contact a {
    display: inline-block;
    text-decoration-skip-ink: auto;
    position: relative;
    cursor: pointer;
    color: rgb(100, 255, 218);
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.contact a::after {
    content: '';
    display: block;
    width: 0px;
    height: 1px;
    position: relative;
    bottom: 0.37em;
    background-color: rgb(100, 255, 218);
    opacity: 0.5;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.contact .heading::before {
    counter-increment: section 1;
    content: '0' counter(section) '.';
    margin-right: 10px;
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-size: 20px;
    position: relative;
    bottom: 4px;
}

.contact .heading::before {
    bottom: 0px;
    font-size: 14px;
}
.contact .heading::after {
    display: none;
}

.contact .btn::after {
    display: none !important;
}

@media (max-width: 62.5em) {
    .contact .heading::before {
        font-size: 13px;
    }
    .contact .heading::after {
        width: unset;
    }
    .contact .title {
        font-size: 50px;
    }
    .contact .heading {
        font-size: 14px;
    }
}
@media (max-width: 48em) {
    .contact {
        padding: 100px 0px;
    }
    .contact .heading::after {
        width: 100%;
    }
    .contact .title {
        font-size: 40px;
    }
}
@media (max-width: 37.5em) {
    .contact .heading::after {
        margin-left: 10px;
    }
}
