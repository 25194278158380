.featured-posts {
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: row;
    align-items: flex-start;
    margin: 0px auto;
    padding: 100px 0px;
}

.featured-posts h4 {
    text-align: center;
}

.featured-posts-grid {
    margin: 0 auto;
    margin-top: 50px;
}

.featured-posts-grid .featured-posts-container {
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
    position: relative;
    gap: 15px;
}

.featured-post-inner {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    display: flexbox;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    width: 100%;
    background-color: rgb(23, 42, 69);
    padding: 2rem 1.75rem;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    border-color: rgb(23, 42, 69);
    border-style: solid;
}

.featured-post:hover .featured-post-inner,
.featured-post:active .featured-post-inner,
.featured-post:focus .featured-post-inner {
    border-color: rgb(100, 255, 218);
    border-style: solid;
}

.featured-post-header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.featured-post-title {
    font-size: 22px;
    color: rgb(204, 214, 246);
    margin: 0px 0px 10px;
}

.featured-post-date {
    display: block;
    font-size: 16px;
    font-weight: lighter;
    color: rgb(204, 214, 246);
}

.featured-post-excerpt {
    font-size: 17px;
    color: rgb(168, 178, 209);
}

.featured-post-reading-time {
    margin-top: 20px;
    font-size: 18px;
    font-weight: lighter;
    color: rgb(100, 255, 218);
}

.featured-post-tags {
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
}

.featured-post-tags li {
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 12px;
    color: rgb(136, 146, 176);
    line-height: 1.75;
    margin-right: 15px;
}

.featured-posts-grid.loading {
    height: 800px;
    margin: unset;
    margin-top: 50px;
}

.featured-posts-grid .stripe {
    height: 30%;
}

@media (max-width: 48em) {
    .featured-post-header {
        display: block;
    }
}
