.not-found {
    min-height: 100vh;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 150px;
    margin: 0px auto;
    text-align: center;
}

.not-found h1 {
    color: rgb(100, 255, 218);
    font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', 'Lucida Console', Monaco, monospace;
    font-size: 12vw;
    line-height: 1;
}

.not-found h2 {
    font-size: 3vw;
    font-weight: 400;
}

.not-found code {
    width: unset;
}

@media (max-width: 75em) {
    .not-found h1 {
        font-size: 7rem;
    }

    .not-found h2 {
        font-size: 2rem;
    }
}

@media (max-width: 48em) {
    .not-found {
        padding-left: 1em;
        padding-right: 1em;
    }
}
